import { Component, Input, OnInit } from '@angular/core';
import { ELEMENT_DATA } from './mock-data';

@Component({
  selector: 'app-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss']
})
export class TableComponent implements OnInit {
  displayedColumns = [
    'case-no',
    'recorded-by',
    'store',
    'customer',
    'address',
    'views',
    'date'
  ];
  dataSource = ELEMENT_DATA;

  @Input() title: string;

  constructor() {
  }

  ngOnInit() {
  }
}
