<mat-toolbar>
  <div class="content menu">
    <img class="sidebar-logo" src="../../assets/logo_new.png" width="125px" height="34px" />
    <div *ngIf="mediaObserver.isActive('xs'); else show_button">
      <span></span>
    </div>
    <ng-template #show_button>
      <button mat-flat-button [routerLink]="'/login'" color="primary">
        Gå til dit dashboard
        <mat-icon matListIcon>login</mat-icon>
      </button>
    </ng-template>
  </div>
</mat-toolbar>
<mat-divider></mat-divider>
<div class="content">
  <app-video-article *ngIf="article.type === resourceType.Video" [videoResource]="article"></app-video-article>
  <app-pvp-article *ngIf="article.type === resourceType.Pvp" [videoResource]="article"></app-pvp-article>
</div>
