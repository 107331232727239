import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { s3ObjectToUrl } from '../../util/s3/s3.util';
import { User } from '../../services/amplify/models';
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  currentUser$: Observable<User>;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.currentUser$ = this.authService.currentUser$;
  }

  getUserAvatar(avatar) {
      return s3ObjectToUrl(avatar);
  }
}
