import { MediaObserver } from '@angular/flex-layout';
import { Component, Input, OnInit } from '@angular/core';
import { productionVideosResourceUrl } from '../../util/video-uri-mapping/video-uri-mapping.util';

@Component({
  selector: 'app-pvp-article',
  templateUrl: 'pvp-article.component.html',
  styleUrls: ['pvp-article.component.scss']
})

export class PvpArticleComponent implements OnInit {
  @Input() public videoResource;

  public productionUrl = productionVideosResourceUrl;

  constructor(public mediaObserver: MediaObserver) { }

  ngOnInit() { }
}
