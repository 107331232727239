<h2>{{ title }}</h2>
<div class="chart-holder">
  <apx-chart #chart
    [series]="data"
    [chart]="options.chart"
    [colors]="options.colors"
    [legend]="options.legend"
    [dataLabels]="options.dataLabels"
    [yaxis]="options.yaxis"
    [xaxis]="options.xaxis">
  </apx-chart>
</div>
