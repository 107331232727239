<h2>{{ title }}</h2>
<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="case-no">
    <th mat-header-cell *matHeaderCellDef> Sagsnr. </th>
    <td mat-cell *matCellDef="let element"> {{element.caseNo}} </td>
  </ng-container>

  <ng-container matColumnDef="recorded-by">
    <th mat-header-cell *matHeaderCellDef> Optaget af </th>
    <td mat-cell *matCellDef="let element"> {{element.recordedBy}} </td>
  </ng-container>

  <ng-container matColumnDef="store">
    <th mat-header-cell *matHeaderCellDef> Butik </th>
    <td mat-cell *matCellDef="let element"> {{element.store}} </td>
  </ng-container>

  <ng-container matColumnDef="customer">
    <th mat-header-cell *matHeaderCellDef> Kunde </th>
    <td mat-cell *matCellDef="let element"> {{element.customer}} </td>
  </ng-container>

  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef> Adresse </th>
    <td mat-cell *matCellDef="let element"> {{element.address}} </td>
  </ng-container>

  <ng-container matColumnDef="views">
    <th mat-header-cell *matHeaderCellDef> Visninger </th>
    <td mat-cell *matCellDef="let element"> {{element.views}} </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Dato </th>
    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
