export const productionVideosResourceUrl = 'https://diialoggstatic100939-prod.s3.eu-central-1.amazonaws.com/public/videos/';

export enum ResourceType {
  Video = 'VIDEO',
  Pvp = 'PVP'
}

export const uriToVideoResourceMap = {
  'respons': {
    title: 'Respons',
    resource: 'respons',
    duration: '01:06',
    type: ResourceType.Video,
    text: 'Fortæl dine kunder hvordan de nemt svarer dig, så ved de at du forventer et svar. Det giver en større sandsynlighed for at få et response.'
  },
  'baggrund': {
    title: 'Baggrund',
    resource: 'baggrund',
    duration: '01:39',
    type: ResourceType.Video,
    text: 'Baggrunden er vigtig når du sender videobeskeder. Her får du 3 gode råd til hvordan du kan udnytte dine omgivelser, når du er på farten.'
  },
  'lyd': {
    title: 'Lyd',
    resource: 'lyd',
    duration: '01:26',
    type: ResourceType.Video,
    text: 'Tal klart og tydeligt, så din besked går rent igennem. Vælg autentiske miljøer, men undgå såvidt muligt steder hvor der er meget ekstern støj.'
  },
  'kamera': {
    title: 'Kamera',
    resource: 'kamera',
    duration: '01:12',
    type: ResourceType.Video,
    text: 'Nikolaj fortæller i denne video, hvordan du skal agere og hvor du skal kigge hen, for på den måde at lave bedre videobeskeder til dine kunder.'
  },
  'den-foerste-sms': {
    title: 'Den første SMS',
    resource: 'foerste_sms',
    duration: '01:53',
    type: ResourceType.Video,
    text: 'Det er vigtigt, når du sender den første SMS, at du ikke kommer i "Ukendt" mappen hos modtageren. Se med her hvordan du undgår det.'
  },
  'smil': {
    title: 'Smil',
    resource: 'smil',
    duration: '01:14',
    type: ResourceType.Video,
    text: 'Smil inden du trykker optag på din telefon, det er nemlig herefter, de første billeder som kunden kommer til at se af dig, bliver optaget.'
  },
  'en-god-approach': {
    title: 'En god approach',
    resource: 'god_approach',
    duration: '01:07',
    type: ResourceType.Video,
    text: 'Vi ved alle, at førstehåndsindtrykket er vigtigt. Her giver Nikolaj nogle gode råd til hvordan videobeskeder kan være gøres mere autentiske.'
  },
  'praesenter-dig-selv': {
    title: 'Præsentér dig selv',
    resource: 'praesenter_dig_selv',
    duration: '01:00',
    type: ResourceType.Video,
    text: 'Start med at sige kundens navn, da det virker meget mere personligt. Præsenter dig selv, hvis det er første kontakt du har med kunden.'
  },
  'kom-godt-igang': {
    title: 'Kom godt igang',
    resource: 'kom_godt_igang',
    duration: '01:42',
    type: ResourceType.Video,
    text: 'Alt begyndelse er svært. Nikolaj hjælper dig i denne video godt på vej til, at få succes med at lave videobeskeder.'
  },
  'intro': {
    title: 'Introduktion',
    resource: 'intro',
    duration: '01:34',
    type: ResourceType.Video,
    text: 'Begynd at lave videobeskeder allerede i dag. Vi har gjort det nemt og intuitivt, så du hurtigt kan komme i gang.'
  },
  'kundeudsagn': {
    title: 'Kundeudsagn',
    resource: 'kundeudsagn',
    duration: '02:39',
    type: ResourceType.Video,
    text: 'Hvad siger jeres kunder? Nikolaj fortæller her om jeres kunders oplevelser med videobeskeder.'
  },
};

export const uriToPvpResourceMap = {
  'efter-fremvisning': {
    title: 'Efter fremvisning',
    resource: 'b9bb9d9f',
    duration: '00:29',
    type: ResourceType.Pvp,
    text: 'Efter endt fremvisning, har du mulighed for at fortælle din kunde hvordan det gik og hvad de næste skridt er.'
  },
  'efter-aabent-hus': {
    title: 'Efter åbent hus',
    resource: '0e93f095',
    duration: '00:42',
    type: ResourceType.Pvp,
    text: 'Videobeskeder er en oplagt mulighed til at opsummere åbent hus arrangementet. Med mimic og udtryk kan du langt nemmere kommunikere udfaldet af dagen.'
  },
  'ved-maeglerrunde': {
    title: 'Ved mæglerrunde',
    resource: '1aa2dddc',
    duration: '00:36',
    type: ResourceType.Pvp,
    text: 'Med videobeskeder kan du på en troværdig måde, fortælle hvorfor kunden gjorde helt rigtigt i at vælge jer!'
  },
  'slutseddel-underskrevet': {
    title: 'Slutseddel underskrevet',
    resource: 'aba323b8',
    duration: '00:31',
    type: ResourceType.Pvp,
    text: 'For at få det bedste renommé, kan du drage fordel af at afslutte med videobeskeder, så kunden kan se dit kropsprog når du siger tak.'
  },
  'opfoelgning-efter-fremvisning': {
    title: 'Opfølgning efter fremvisning',
    resource: '3fb06eff',
    duration: '00:23',
    type: ResourceType.Pvp,
    text: 'Øg dine chancer for at et svar med videobeskeder. I eksemplet her, viser Nikolaj hvordan du øger dine muligheder for stadig at hjælpe køber, selvom den aktuelle fremvisning måske ikke var det rette for dem.'
  },
  'kunder-i-koeberkartotek': {
    title: 'Kunder i køberkartotek',
    resource: '13d88837',
    duration: '00:33',
    type: ResourceType.Pvp,
    text: 'Ved at sende en personlig videobesked, er du allerede langt foran dine konkurrenter, når køber skal vælge hvilke boliger de vil se.'
  },
  'naar-huset-er-solgt': {
    title: 'Når huset er solgt',
    resource: '7f6ccb59',
    duration: '00:19',
    type: ResourceType.Pvp,
    text: 'For at få det bedste renommé, kan du drage fordel af at afslutte med videobeskeder, så kunden kan se dit kropsprog når du siger tak.'
  },
  'koeberpotentiale': {
    title: 'Køberpotentiale',
    resource: '5404f0d1',
    duration: '00:28',
    type: ResourceType.Pvp,
    text: 'Videobeskeder er en nem indtagelig informationskilde, hvorfor du skal bruge den til at holde dine kunder underrettet om fremskridt.'
  },
  'naar-kontrakt-er-underskrevet': {
    title: 'Når kontrakt er underskrevet',
    resource: '5b32f851',
    duration: '00:42',
    type: ResourceType.Pvp,
    text: 'Lad dine kunder vide at de er i trygge hænder, og forbered dem på den kommunikationsform de kan forvente fra dig som mægler.'
  },
  'efter-v1': {
    title: 'Efter V1',
    resource: 'b3e6809a',
    duration: '00:45',
    type: ResourceType.Pvp,
    text: 'Med videobeskeder kan du på en troværdig måde, promovere hvorfor kunden skal vælge netop jer!'
  },
  'kontakt-vedr-v1': {
    title: 'Kontakt vedr. V1',
    resource: '6c1ecff8',
    duration: '00:51',
    type: ResourceType.Pvp,
    text: 'Med videobeskeder kan du på en troværdig måde, promovere hvorfor kunden skal vælge netop jer!'
  },
};

export const allArticles = Object.assign({...uriToVideoResourceMap}, {...uriToPvpResourceMap});
