import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { ApexAxisChartSeries } from 'ng-apexcharts/lib/model/apex-types';

@Component({
  selector: 'app-graph',
  templateUrl: 'graph.component.html',
  styleUrls: ['graph.component.scss']
})
export class GraphComponent implements OnInit, AfterViewInit {
  @ViewChild('chart', {static: false}) chart: ChartComponent;
  options: ApexOptions = GRAPH_OPTIONS;

  private _data: ApexAxisChartSeries;
  @Input() title: string;

  @Input() set data(data: ApexAxisChartSeries) {
    this._data = JSON.parse(JSON.stringify(data));
  }

  get data(): ApexAxisChartSeries {
    return this._data;
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }
}

export const GRAPH_OPTIONS: ApexOptions = {
  chart: {
    type: 'area',
    height: 500,
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 500
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#009d75', '#989898'],
  legend: {
    position: 'top',
    horizontalAlign: 'right',
  },
  yaxis: {
    tickAmount: 1,
    min: 0,
    forceNiceScale: true,
  },
  xaxis: {
    type: 'category',
    categories: [],
    labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
        style: {
            colors: [],
            fontSize: '11px',
            fontFamily: 'Roboto, Arial, sans-serif',
            fontWeight: 400,
        },
        offsetX: 7
    },
  }
};
