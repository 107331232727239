import { Component, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { allArticles, ResourceType } from '../../shared/util/video-uri-mapping/video-uri-mapping.util';

@Component({
  selector: 'app-articles',
  templateUrl: 'articles.component.html',
  styleUrls: ['articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  public article;
  public resourceType = ResourceType;

  constructor(
    private route: ActivatedRoute,
    public mediaObserver: MediaObserver
  ) {}

  ngOnInit() {
    this.route.params.pipe(
      map(params => allArticles[params.id])
    ).subscribe(result => {
      this.article = result;
      console.log(this.article);
    })
  }
}
