import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';
import { RoutingStateService } from '../../services/routing-state/routing-state.service';


@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private routingState: RoutingStateService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return Auth.currentAuthenticatedUser().then( cau => {
      if (cau){
        this.router.navigate(['statistics']).then(() => {
          console.log('navigate to dashboard');
        });
        return false;
      } else {
        return true;
      }
    }).catch(err => {
      console.log(err);
      return true;
    });
  }
}
