import { Injectable } from '@angular/core';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../amplify/graphql/queries";
import { BehaviorSubject } from "rxjs";
import { User } from "../amplify/models";

@Injectable()

export class UsersService {

  users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);

  constructor() {
    this.setUsersList();
  }

  async setUsersList() {
    const response = await API.graphql(graphqlOperation(queries.listUsers, { limit: 5000 }));
    const responseWithoutOBO = response['data'].listUsers.items
      .filter(user => !user.email.startsWith('obo-'))
      .filter(user => !user.deactivated);
    this.users$.next(responseWithoutOBO);
  }

}
