export interface Case {
  id: string;
}

export const Cases: Case[] = [
  { id: '12345' },
  { id: '54321' },
  { id: '09876' },
  { id: '67890' }
];
