import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { API, graphqlOperation } from 'aws-amplify';
import {ShopService} from "../shop/shop.service";
import {Shop} from '../amplify/models';
import {filter, map} from "rxjs/operators";
import {GraphQLResult} from "@aws-amplify/api-graphql";

@Injectable()
export class VideosService {

  companies$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  constructor(private shopService: ShopService) {
    this.setVideos(shopService.shops);
  }

  async setVideos(shopsSubject: BehaviorSubject<Shop[]>) {
    shopsSubject.subscribe(shops => {
      const promises = shops
        .filter(shops => !!shops)
        .map(shop => {
          // @ts-ignore
          return shop.companyID as string
        })
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(companyID => {
            return API.graphql(graphqlOperation(listCompanys, {
              id: companyID
            }));
        })

      if (promises.length < 1) {
        return
      }

      Promise.all(promises as Promise<GraphQLResult>[]).then(getCompanyResults => {
        const companies = getCompanyResults.map(getCompanyResult => {
          // @ts-ignore
          return getCompanyResult['data'].getCompany;
        })

        this.companies$.next(companies);
      })
    })
  }

}

const listCompanys = `query GetCompany($id: ID!) {
    getCompany(id: $id) {
        id
        name
        shops(limit: 50000) {
          items {
            id
        		name
        		baseUrl
        		companyID
        		deactivated
            employees(limit: 50000) {
              items {
                id
                cognitoUsername
                firstName
                lastName
                jobTitle
                deactivated
                email
                number
                expoPushToken
                avatar {
                  bucket
                  region
                  key
                  mimeType
                }
                shopID
                owner
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                videos(limit: 50000) {
                  items {
                  	id
      							uuid
      							minutes
      							seconds
      							uploadedFile {
      							  bucket
      							  region
      							  key
      							  mimeType
      							}
      							convertedFile {
      							  bucket
      							  region
      							  key
      							  mimeType
      							}
      							thumpnailFile {
      							  bucket
      							  region
      							  key
      							  mimeType
      							}
      							created
      							state
      							owner
      							userID
      							_version
      							_deleted
      							_lastChangedAt
      							createdAt
      							updatedAt
                    shares(limit: 50000) {
                      items {
      								  id
      								  videoID
      								  videoShortKey
      								  link
      								  sagsnummer
      								  adresse
      								  navn
      								  phoneNumber
      								  owner
      								  disabled
      								  _version
      								  _deleted
      								  _lastChangedAt
      								  createdAt
      								  updatedAt
                        views(limit: 50000) {
                                items {
      														  id
      														  videoShareID
      														  viewDateTime
      														  videoStarted
      														  percentageViewed
      														  owner
      														  _version
      														  _deleted
      														  _lastChangedAt
      														  createdAt
      														  updatedAt
      														}
      														nextToken
      														startedAt
                        }
      								}
      								nextToken
      								startedAt
                    }
                  }
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
          }
          nextToken
      		startedAt
        }
      }
  }`
