import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {

  loginInvalid: boolean;
  form: FormGroup;
  loadingUser = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  async onSubmit() {
    this.loginInvalid = false;
    if (this.form.valid) {
      try {
        const username = this.form.get('username').value;
        const password = this.form.get('password').value;
        this.loadingUser = true;
        const error = await this.authService.signIn(username, password);
        if (error && (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException')) {
          this.loginInvalid = true;
        }
        this.router.navigate(['/statistics']);
        this.loadingUser = false;
      } catch (err) {
        this.loginInvalid = true;
      }
    }
  }
}
