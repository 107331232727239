<div class="select-input-holder">
  <mat-form-field appearance="fill" class="fluid">
    <mat-label>Shops</mat-label>
    <mat-select [(value)]="selectedShop" (selectionChange)="selectShop($event)" panelClass="selectorz">
      <mat-option *ngFor="let shop of shops" [value]="shop">
        {{ shop.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
