
export interface EntryMock {
  caseNo: number;
  recordedBy: string;
  store: string;
  customer: string;
  address: string;
  views: number;
  date: string;
}

export const ELEMENT_DATA: EntryMock[] = [
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
  {
    caseNo: 1,
    recordedBy: 'Jens Jensen',
    store: 'Søborg',
    customer: 'Jørgen Jørgensen',
    address: 'Sønder Boulevard 20, 3.th',
    views: 56,
    date: '05/06/20'
  },
];
