import {Injectable} from '@angular/core';
import {API, graphqlOperation} from 'aws-amplify';
import {BehaviorSubject} from 'rxjs';
import {Shop} from '../amplify/models';
import {AuthService} from "../auth/auth.service";
import {filter} from "rxjs/operators";
import {tapOnce} from "../../util/tab-once/tab-once.util";
import * as queries from "../amplify/graphql/queries";

export interface CurrentShop {
  companyID: string
  shopID: string
}
@Injectable()
export class ShopService {

  currentShop$: BehaviorSubject<CurrentShop> = new BehaviorSubject<CurrentShop>(null);
  shops: BehaviorSubject<Shop[]> = new BehaviorSubject<Shop[]>([]);

  constructor(private authService: AuthService) {
    this.loadDefaultShop();
    this.loadShops();
  }

  private loadDefaultShop() {
    this.authService.currentUser$.pipe(
      filter(user => !!user),
      tapOnce((user) => {
        this.currentShop$.next({
          companyID: user.shop.companyID,
          shopID: user.shopID
        });
      })
    )
  }

  private async loadShops() {
    API.graphql(graphqlOperation(queries.listShops))
      // @ts-ignore
      .then(update => {
        this.shops.next(update.data.listShops.items.filter(shop =>
          !shop.deactivated
        ));
      });
  }

  selectShop(shop: Shop) {
    this.currentShop$.next({
      companyID: shop["companyID"],
      shopID: shop.id
    })
  }

}
