import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";

export function tapOnce<T>(fn: (value)=> void) {
  return function(source: Observable<T>) {
    source
      .pipe(
        take(1),
        tap(value => fn(value))
      )
      .subscribe();

    return source;
  };
}
