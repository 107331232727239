<div class="breadcrumbs">
  <span>Gode råd » Tips & idéer »</span>
</div>
<h1 class="biggest">{{ videoResource.title }}</h1>
<h2>
  <span>
    {{ videoResource.text }}
  </span>
</h2>
<video controls [poster]="productionUrl + videoResource.resource + '.jpg'" autoplay>
  <source [src]="productionUrl + videoResource.resource + '.mp4'" type="video/mp4">
</video>

