import { Injectable } from '@angular/core';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { User } from '../amplify/models';
import * as queries from "../amplify/graphql/queries";

@Injectable()
export class AuthService {

  currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor() {
    this.loadCurrentUser();
  }

  async loadCurrentUser() {
    try {
      await Auth.currentAuthenticatedUser().then(async user => {
        // this very beautiful yes
        const response = await API.graphql(graphqlOperation(queries.userByEmail, { email: user.attributes.email}));
        const id = response['data'].userByEmail.items[0].id;
        const fullUser = await API.graphql(graphqlOperation(queries.getUser, { id: id }));
        const shopId = fullUser['data'].getUser.shopID;
        const shopResponse = await API.graphql(graphqlOperation(queries.getShop, { id: shopId }));
        const currentUser = fullUser['data'].getUser;
        currentUser['shop'] = shopResponse['data'].getShop;
        this.currentUser$.next(currentUser);
      });
    } catch (e) {
      console.log("No current user at the moment", e);
      setTimeout(async () => {
        await this.loadCurrentUser();
      }, 500)
    }
  }

  async signIn(username: string, password: string) {
    try {
      await Auth.signIn(username, password);
      await this.loadCurrentUser();
    } catch (error) {
      console.log('error signing in', error);
      return error;
    }
  }

  async signOut() {
    try {
       await Auth.signOut();
       this.currentUser$.next(null);
       window.location.reload();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
}
