import { Component, Input, OnInit } from '@angular/core';
import { productionVideosResourceUrl } from '../../util/video-uri-mapping/video-uri-mapping.util';

@Component({
  selector: 'app-video-article',
  templateUrl: 'video-article.component.html',
  styleUrls: ['video-article.component.scss']
})

export class VideoArticleComponent implements OnInit {

  @Input() public videoResource;

  public productionUrl = productionVideosResourceUrl;

  constructor() { }

  ngOnInit() {
  }
}
