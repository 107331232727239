import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot',
  templateUrl: 'forgot.component.html'
})

export class ForgotComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
