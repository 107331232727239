import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';

@Injectable()
export class PublicArticleGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return Auth.currentAuthenticatedUser().then( cau => {
      if (cau) {
        this.router.navigate(['help', route.params.id]).then(() => {
          console.log('navigate to dashboard/help');
        });
        return false;
      } else {
        return true;
      }
    }).catch(err => {
      console.log(err);
      return true;
    });
  }
}
