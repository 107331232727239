import {Injectable} from '@angular/core';
import {API, graphqlOperation} from 'aws-amplify';

@Injectable()
export class VideosharesService {

  constructor() {
  }

  async toggleVideoShareDisabled(videoShareId: string, videoShareVersion: string, toState: boolean) {

    const updateVideoShare = {
      input: {
        id: videoShareId,
        disabled: toState,
        skipDisabling: toState === false,
        _version: videoShareVersion
      }
    };

    await API.graphql(graphqlOperation(updateVideoShareMutation, updateVideoShare));
  }

}

const updateVideoShareMutation = /* GraphQL */ `
  mutation UpdateVideoShare(
    $input: UpdateVideoShareInput!
    $condition: ModelVideoShareConditionInput
  ) {
    updateVideoShare(input: $input, condition: $condition) {
      id
      disabled
      skipDisabling
      _version
    }
  }
`;
