<div class="breadcrumbs">
  <span>Gode råd » Videoeksempler »</span>
</div>
<h1 class="biggest">{{ videoResource.title }}</h1>
<h2>Vi ved godt vi ikke er ejendomsmæglere, men vi har alligevel prøvet - for at give dig inspiration til dine personlige videoer. <br /><br />
{{videoResource.text}}</h2>

<iframe *ngIf="mediaObserver.isActive('xs'); else fixed_width" width="100%" height="675" [src]="'https://video.diialogg.com/v/' + videoResource.resource + '?anonymous' | safe" scrolling="no"></iframe>
<ng-template #fixed_width>
  <div style="margin: 0 auto">
  <iframe width="375" height="675" [src]="'https://video.diialogg.com/v/' + videoResource.resource + '?anonymous' | safe" scrolling="no"></iframe>
</div>
</ng-template>
