<div fxLayout="row" fxLayoutAlign="space-between center">
  <h1>{{title}}</h1>
  <a class="profile" [routerLink]="'/profile'">
    <ng-container *ngIf="currentUser$ | async as currentUser">
      <div class="profile-text">{{ currentUser?.firstName }} {{ currentUser?.lastName }}</div>
      <div *ngIf="currentUser" class="profile-picture"
           [ngStyle]="{
             'background-image': 'url(' + getUserAvatar(currentUser?.avatar) + ')',
             'background-size': 'contain'
           }"
      ></div>
    </ng-container>
  </a>
</div>
<mat-divider></mat-divider>
