export interface Employee {
  id: string;
  name: string;
}
export const Employees: Employee[] = [
  {id: '1', name: 'Jørgen Jørgensen'},
  {id: '2', name: 'Børge Børgesen'},
  {id: '3', name: 'Anders Andersen'}
];

