import { MatToolbarModule } from '@angular/material/toolbar';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './home/login/login.component';
import { ForgotComponent } from './home/forgot/forgot.component';
import { ArticlesComponent } from './home/articles/articles.component';
import { HomeComponent } from './home/home.component';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Amplify } from 'aws-amplify';
import awsconfig from './shared/services/amplify/aws-exports';
import { AmplifyAngularModule, AmplifyModules, AmplifyService } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import Interactions from '@aws-amplify/interactions';
import Storage from '@aws-amplify/storage';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { LoginGuard } from './shared/guards/login/login.guard';
import { AdminGuard } from './shared/guards/admin/admin.guard';
import { PublicArticleGuard } from './shared/guards/public-article/public-article.guard';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import da from '@angular/common/locales/da';
import { registerLocaleData } from '@angular/common';

registerLocaleData(da);

const isProduction = () => {
  return awsconfig.aws_user_files_s3_bucket.includes('prod');
}

Amplify.configure({
  ...awsconfig,
  "aws_user_pools_web_client_id":  isProduction() ? "1s04ij7hjil1ergsf00s7nrbfc" : "2j5q3uesjctk6j2k296guuirat"
});

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotComponent,
    HomeComponent,
    ArticlesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FlexLayoutModule,
    SharedModule.forRoot(),
    AmplifyAngularModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatToolbarModule
  ],
  entryComponents: [],
  providers: [
    AuthGuard,
    LoginGuard,
    AdminGuard,
    PublicArticleGuard,
    {
      provide: AmplifyService,
      useFactory:  () => {
        return AmplifyModules({
          Auth,
          Storage,
          Interactions,
        });
      }
    },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    { provide: LOCALE_ID, useValue: "da-DK" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
