import { Component, OnInit } from '@angular/core';
import { Employee, Employees } from './employee-mock';
import { Case, Cases } from './case-mock';

@Component({
  selector: 'app-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})

export class SearchComponent implements OnInit {

  employees: Employee[];
  cases: Case[];

  constructor() {
  }

  ngOnInit() {
    this.employees = Employees;
    this.cases = Cases;
  }
}
