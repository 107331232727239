/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFeatureFlags = /* GraphQL */ `
  subscription OnCreateFeatureFlags {
    onCreateFeatureFlags {
      id
      studioFavorites
      studioDraftsMenu
      studioShareWithColleagues
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFeatureFlags = /* GraphQL */ `
  subscription OnUpdateFeatureFlags {
    onUpdateFeatureFlags {
      id
      studioFavorites
      studioDraftsMenu
      studioShareWithColleagues
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFeatureFlags = /* GraphQL */ `
  subscription OnDeleteFeatureFlags {
    onDeleteFeatureFlags {
      id
      studioFavorites
      studioDraftsMenu
      studioShareWithColleagues
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      shops {
        items {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      shops {
        items {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      shops {
        items {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateShop = /* GraphQL */ `
  subscription OnCreateShop {
    onCreateShop {
      id
      name
      subname
      cvrNumber
      senderId
      logo {
        bucket
        region
        key
        mimeType
      }
      logoHeight
      logoWidth
      baseUrl
      privacyPolicyUrl
      mailTemplate {
        locale
        html
      }
      deactivated
      logoBackgroundHexColor
      headerBackgroundColor
      headerTextColor
      footerBackgroundColor
      footerTextColor
      canAttachLinks
      latitude
      longitude
      googleMaps {
        center {
          latitude
          longitude
        }
        zoom
        markers {
          latitude
          longitude
        }
      }
      segment
      cogBIntegration {
        tenantId
        resourceGroupId
      }
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      employees {
        items {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateShop = /* GraphQL */ `
  subscription OnUpdateShop {
    onUpdateShop {
      id
      name
      subname
      cvrNumber
      senderId
      logo {
        bucket
        region
        key
        mimeType
      }
      logoHeight
      logoWidth
      baseUrl
      privacyPolicyUrl
      mailTemplate {
        locale
        html
      }
      deactivated
      logoBackgroundHexColor
      headerBackgroundColor
      headerTextColor
      footerBackgroundColor
      footerTextColor
      canAttachLinks
      latitude
      longitude
      googleMaps {
        center {
          latitude
          longitude
        }
        zoom
        markers {
          latitude
          longitude
        }
      }
      segment
      cogBIntegration {
        tenantId
        resourceGroupId
      }
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      employees {
        items {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteShop = /* GraphQL */ `
  subscription OnDeleteShop {
    onDeleteShop {
      id
      name
      subname
      cvrNumber
      senderId
      logo {
        bucket
        region
        key
        mimeType
      }
      logoHeight
      logoWidth
      baseUrl
      privacyPolicyUrl
      mailTemplate {
        locale
        html
      }
      deactivated
      logoBackgroundHexColor
      headerBackgroundColor
      headerTextColor
      footerBackgroundColor
      footerTextColor
      canAttachLinks
      latitude
      longitude
      googleMaps {
        center {
          latitude
          longitude
        }
        zoom
        markers {
          latitude
          longitude
        }
      }
      segment
      cogBIntegration {
        tenantId
        resourceGroupId
      }
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      employees {
        items {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      cognitoUsername
      firstName
      lastName
      jobTitle
      email
      number
      expoPushToken
      avatar {
        bucket
        region
        key
        mimeType
      }
      monthlyGoal
      locale
      cogBActorId
      deactivated
      shopID
      owner
      statisticsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      statistics {
        id
        owner
        lastVideoShared
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      videos {
        items {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cogBAppointments {
        items {
          id
          appointmentId
          appointmentStart
          appointmentEnd
          meetingType
          status
          regarding
          pushNotificationsSent
          cOgBActorId
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      cognitoUsername
      firstName
      lastName
      jobTitle
      email
      number
      expoPushToken
      avatar {
        bucket
        region
        key
        mimeType
      }
      monthlyGoal
      locale
      cogBActorId
      deactivated
      shopID
      owner
      statisticsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      statistics {
        id
        owner
        lastVideoShared
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      videos {
        items {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cogBAppointments {
        items {
          id
          appointmentId
          appointmentStart
          appointmentEnd
          meetingType
          status
          regarding
          pushNotificationsSent
          cOgBActorId
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      cognitoUsername
      firstName
      lastName
      jobTitle
      email
      number
      expoPushToken
      avatar {
        bucket
        region
        key
        mimeType
      }
      monthlyGoal
      locale
      cogBActorId
      deactivated
      shopID
      owner
      statisticsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      statistics {
        id
        owner
        lastVideoShared
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      videos {
        items {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cogBAppointments {
        items {
          id
          appointmentId
          appointmentStart
          appointmentEnd
          meetingType
          status
          regarding
          pushNotificationsSent
          cOgBActorId
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateUserStatistics = /* GraphQL */ `
  subscription OnCreateUserStatistics($owner: String) {
    onCreateUserStatistics(owner: $owner) {
      id
      owner
      lastVideoShared
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateUserStatistics = /* GraphQL */ `
  subscription OnUpdateUserStatistics($owner: String) {
    onUpdateUserStatistics(owner: $owner) {
      id
      owner
      lastVideoShared
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteUserStatistics = /* GraphQL */ `
  subscription OnDeleteUserStatistics($owner: String) {
    onDeleteUserStatistics(owner: $owner) {
      id
      owner
      lastVideoShared
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateVideo = /* GraphQL */ `
  subscription OnCreateVideo($owner: String) {
    onCreateVideo(owner: $owner) {
      id
      uuid
      minutes
      seconds
      durationInMillis
      durationIntro
      draftID
      uploadedFile {
        bucket
        region
        key
        mimeType
      }
      convertedFile {
        bucket
        region
        key
        mimeType
      }
      thumpnailFile {
        bucket
        region
        key
        mimeType
      }
      created
      pinned
      state
      owner
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      draft {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      shares {
        items {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          cogBAppointmentId
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo($owner: String) {
    onUpdateVideo(owner: $owner) {
      id
      uuid
      minutes
      seconds
      durationInMillis
      durationIntro
      draftID
      uploadedFile {
        bucket
        region
        key
        mimeType
      }
      convertedFile {
        bucket
        region
        key
        mimeType
      }
      thumpnailFile {
        bucket
        region
        key
        mimeType
      }
      created
      pinned
      state
      owner
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      draft {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      shares {
        items {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          cogBAppointmentId
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo($owner: String) {
    onDeleteVideo(owner: $owner) {
      id
      uuid
      minutes
      seconds
      durationInMillis
      durationIntro
      draftID
      uploadedFile {
        bucket
        region
        key
        mimeType
      }
      convertedFile {
        bucket
        region
        key
        mimeType
      }
      thumpnailFile {
        bucket
        region
        key
        mimeType
      }
      created
      pinned
      state
      owner
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      draft {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      shares {
        items {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          cogBAppointmentId
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateVideoDraft = /* GraphQL */ `
  subscription OnCreateVideoDraft($owner: String) {
    onCreateVideoDraft(owner: $owner) {
      id
      durationInMillis
      name
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      pinned
      share
      addIntro
      addOverlayLogo
      owner
      copyOfDraftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      createdVideos {
        items {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      copyOf {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      clips {
        items {
          id
          title
          index
          durationInMillis
          durationIntro
          landscape
          pinned
          owner
          draftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateVideoDraft = /* GraphQL */ `
  subscription OnUpdateVideoDraft($owner: String) {
    onUpdateVideoDraft(owner: $owner) {
      id
      durationInMillis
      name
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      pinned
      share
      addIntro
      addOverlayLogo
      owner
      copyOfDraftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      createdVideos {
        items {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      copyOf {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      clips {
        items {
          id
          title
          index
          durationInMillis
          durationIntro
          landscape
          pinned
          owner
          draftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteVideoDraft = /* GraphQL */ `
  subscription OnDeleteVideoDraft($owner: String) {
    onDeleteVideoDraft(owner: $owner) {
      id
      durationInMillis
      name
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      pinned
      share
      addIntro
      addOverlayLogo
      owner
      copyOfDraftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      createdVideos {
        items {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      copyOf {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      clips {
        items {
          id
          title
          index
          durationInMillis
          durationIntro
          landscape
          pinned
          owner
          draftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateVideoClip = /* GraphQL */ `
  subscription OnCreateVideoClip($owner: String) {
    onCreateVideoClip(owner: $owner) {
      id
      title
      index
      durationInMillis
      durationIntro
      clip {
        bucket
        region
        key
        mimeType
      }
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      landscape
      pinned
      owner
      draftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      draft {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateVideoClip = /* GraphQL */ `
  subscription OnUpdateVideoClip($owner: String) {
    onUpdateVideoClip(owner: $owner) {
      id
      title
      index
      durationInMillis
      durationIntro
      clip {
        bucket
        region
        key
        mimeType
      }
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      landscape
      pinned
      owner
      draftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      draft {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteVideoClip = /* GraphQL */ `
  subscription OnDeleteVideoClip($owner: String) {
    onDeleteVideoClip(owner: $owner) {
      id
      title
      index
      durationInMillis
      durationIntro
      clip {
        bucket
        region
        key
        mimeType
      }
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      landscape
      pinned
      owner
      draftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      draft {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        share
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateSharedVideo = /* GraphQL */ `
  subscription OnCreateSharedVideo {
    onCreateSharedVideo {
      id
      durationInMillis
      clip {
        bucket
        region
        key
        mimeType
      }
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      name
      sharedBy
      draftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateSharedVideo = /* GraphQL */ `
  subscription OnUpdateSharedVideo {
    onUpdateSharedVideo {
      id
      durationInMillis
      clip {
        bucket
        region
        key
        mimeType
      }
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      name
      sharedBy
      draftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteSharedVideo = /* GraphQL */ `
  subscription OnDeleteSharedVideo {
    onDeleteSharedVideo {
      id
      durationInMillis
      clip {
        bucket
        region
        key
        mimeType
      }
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      name
      sharedBy
      draftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        logoHeight
        logoWidth
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        canAttachLinks
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        cogBIntegration {
          tenantId
          resourceGroupId
        }
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateVideoShare = /* GraphQL */ `
  subscription OnCreateVideoShare($owner: String) {
    onCreateVideoShare(owner: $owner) {
      id
      videoID
      videoShortKey
      link
      sagsnummer
      adresse
      externalLink
      navn
      disabled
      skipDisabling
      phoneNumber
      message
      owner
      cogBAppointmentId
      state
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      video {
        id
        uuid
        minutes
        seconds
        durationInMillis
        durationIntro
        draftID
        uploadedFile {
          bucket
          region
          key
          mimeType
        }
        convertedFile {
          bucket
          region
          key
          mimeType
        }
        thumpnailFile {
          bucket
          region
          key
          mimeType
        }
        created
        pinned
        state
        owner
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draft {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shares {
          nextToken
          startedAt
        }
      }
      views {
        items {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateVideoShare = /* GraphQL */ `
  subscription OnUpdateVideoShare($owner: String) {
    onUpdateVideoShare(owner: $owner) {
      id
      videoID
      videoShortKey
      link
      sagsnummer
      adresse
      externalLink
      navn
      disabled
      skipDisabling
      phoneNumber
      message
      owner
      cogBAppointmentId
      state
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      video {
        id
        uuid
        minutes
        seconds
        durationInMillis
        durationIntro
        draftID
        uploadedFile {
          bucket
          region
          key
          mimeType
        }
        convertedFile {
          bucket
          region
          key
          mimeType
        }
        thumpnailFile {
          bucket
          region
          key
          mimeType
        }
        created
        pinned
        state
        owner
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draft {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shares {
          nextToken
          startedAt
        }
      }
      views {
        items {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteVideoShare = /* GraphQL */ `
  subscription OnDeleteVideoShare($owner: String) {
    onDeleteVideoShare(owner: $owner) {
      id
      videoID
      videoShortKey
      link
      sagsnummer
      adresse
      externalLink
      navn
      disabled
      skipDisabling
      phoneNumber
      message
      owner
      cogBAppointmentId
      state
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      video {
        id
        uuid
        minutes
        seconds
        durationInMillis
        durationIntro
        draftID
        uploadedFile {
          bucket
          region
          key
          mimeType
        }
        convertedFile {
          bucket
          region
          key
          mimeType
        }
        thumpnailFile {
          bucket
          region
          key
          mimeType
        }
        created
        pinned
        state
        owner
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          cogBActorId
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draft {
          id
          durationInMillis
          name
          pinned
          share
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shares {
          nextToken
          startedAt
        }
      }
      views {
        items {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateVideoShareSMSStatus = /* GraphQL */ `
  subscription OnCreateVideoShareSMSStatus($owner: String) {
    onCreateVideoShareSMSStatus(owner: $owner) {
      id
      videoShareID
      deliveryReport
      status
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShare {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        cogBAppointmentId
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateVideoShareSMSStatus = /* GraphQL */ `
  subscription OnUpdateVideoShareSMSStatus($owner: String) {
    onUpdateVideoShareSMSStatus(owner: $owner) {
      id
      videoShareID
      deliveryReport
      status
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShare {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        cogBAppointmentId
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteVideoShareSMSStatus = /* GraphQL */ `
  subscription OnDeleteVideoShareSMSStatus($owner: String) {
    onDeleteVideoShareSMSStatus(owner: $owner) {
      id
      videoShareID
      deliveryReport
      status
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShare {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        cogBAppointmentId
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateVideoShareView = /* GraphQL */ `
  subscription OnCreateVideoShareView($owner: String) {
    onCreateVideoShareView(owner: $owner) {
      id
      videoShareID
      viewDateTime
      videoStarted
      percentageViewed
      rating
      comment
      ctas {
        count
        type
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShare {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        cogBAppointmentId
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      notifications {
        items {
          id
          videoShareViewID
          seen
          seenDateTime
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateVideoShareView = /* GraphQL */ `
  subscription OnUpdateVideoShareView($owner: String) {
    onUpdateVideoShareView(owner: $owner) {
      id
      videoShareID
      viewDateTime
      videoStarted
      percentageViewed
      rating
      comment
      ctas {
        count
        type
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShare {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        cogBAppointmentId
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      notifications {
        items {
          id
          videoShareViewID
          seen
          seenDateTime
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteVideoShareView = /* GraphQL */ `
  subscription OnDeleteVideoShareView($owner: String) {
    onDeleteVideoShareView(owner: $owner) {
      id
      videoShareID
      viewDateTime
      videoStarted
      percentageViewed
      rating
      comment
      ctas {
        count
        type
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShare {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        cogBAppointmentId
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          durationIntro
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      notifications {
        items {
          id
          videoShareViewID
          seen
          seenDateTime
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateVideoShareViewNotification = /* GraphQL */ `
  subscription OnCreateVideoShareViewNotification($owner: String) {
    onCreateVideoShareViewNotification(owner: $owner) {
      id
      videoShareViewID
      seen
      seenDateTime
      type
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShareView {
        id
        videoShareID
        viewDateTime
        videoStarted
        percentageViewed
        rating
        comment
        ctas {
          count
          type
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          cogBAppointmentId
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateVideoShareViewNotification = /* GraphQL */ `
  subscription OnUpdateVideoShareViewNotification($owner: String) {
    onUpdateVideoShareViewNotification(owner: $owner) {
      id
      videoShareViewID
      seen
      seenDateTime
      type
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShareView {
        id
        videoShareID
        viewDateTime
        videoStarted
        percentageViewed
        rating
        comment
        ctas {
          count
          type
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          cogBAppointmentId
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteVideoShareViewNotification = /* GraphQL */ `
  subscription OnDeleteVideoShareViewNotification($owner: String) {
    onDeleteVideoShareViewNotification(owner: $owner) {
      id
      videoShareViewID
      seen
      seenDateTime
      type
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShareView {
        id
        videoShareID
        viewDateTime
        videoStarted
        percentageViewed
        rating
        comment
        ctas {
          count
          type
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          cogBAppointmentId
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateVideosAndViewsStatistics = /* GraphQL */ `
  subscription OnCreateVideosAndViewsStatistics($owner: String) {
    onCreateVideosAndViewsStatistics(owner: $owner) {
      id
      videos
      views
      date
      dateSearchable
      companyID
      shopID
      userID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVideosAndViewsStatistics = /* GraphQL */ `
  subscription OnUpdateVideosAndViewsStatistics($owner: String) {
    onUpdateVideosAndViewsStatistics(owner: $owner) {
      id
      videos
      views
      date
      dateSearchable
      companyID
      shopID
      userID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVideosAndViewsStatistics = /* GraphQL */ `
  subscription OnDeleteVideosAndViewsStatistics($owner: String) {
    onDeleteVideosAndViewsStatistics(owner: $owner) {
      id
      videos
      views
      date
      dateSearchable
      companyID
      shopID
      userID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateExpoPushNotification = /* GraphQL */ `
  subscription OnCreateExpoPushNotification($owner: String) {
    onCreateExpoPushNotification(owner: $owner) {
      id
      to
      data
      title
      body
      sentDateTime
      owner
      expoPushNotificationTicket {
        statusCode
        body
      }
      expoPushNotificationRecipt {
        statusCode
        body
      }
      videoShareViewNotificationID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      videoShareViewNotification {
        id
        videoShareViewID
        seen
        seenDateTime
        type
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShareView {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const onUpdateExpoPushNotification = /* GraphQL */ `
  subscription OnUpdateExpoPushNotification($owner: String) {
    onUpdateExpoPushNotification(owner: $owner) {
      id
      to
      data
      title
      body
      sentDateTime
      owner
      expoPushNotificationTicket {
        statusCode
        body
      }
      expoPushNotificationRecipt {
        statusCode
        body
      }
      videoShareViewNotificationID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      videoShareViewNotification {
        id
        videoShareViewID
        seen
        seenDateTime
        type
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShareView {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const onDeleteExpoPushNotification = /* GraphQL */ `
  subscription OnDeleteExpoPushNotification($owner: String) {
    onDeleteExpoPushNotification(owner: $owner) {
      id
      to
      data
      title
      body
      sentDateTime
      owner
      expoPushNotificationTicket {
        statusCode
        body
      }
      expoPushNotificationRecipt {
        statusCode
        body
      }
      videoShareViewNotificationID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
      videoShareViewNotification {
        id
        videoShareViewID
        seen
        seenDateTime
        type
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShareView {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const onCreateCogBAppointment = /* GraphQL */ `
  subscription OnCreateCogBAppointment($owner: String) {
    onCreateCogBAppointment(owner: $owner) {
      id
      appointmentId
      appointmentStart
      appointmentEnd
      attendee {
        name
        number
        email
      }
      notifyee {
        name
        number
        email
      }
      meetingType
      status
      regarding
      pushNotificationsSent
      cOgBActorId
      owner
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateCogBAppointment = /* GraphQL */ `
  subscription OnUpdateCogBAppointment($owner: String) {
    onUpdateCogBAppointment(owner: $owner) {
      id
      appointmentId
      appointmentStart
      appointmentEnd
      attendee {
        name
        number
        email
      }
      notifyee {
        name
        number
        email
      }
      meetingType
      status
      regarding
      pushNotificationsSent
      cOgBActorId
      owner
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteCogBAppointment = /* GraphQL */ `
  subscription OnDeleteCogBAppointment($owner: String) {
    onDeleteCogBAppointment(owner: $owner) {
      id
      appointmentId
      appointmentStart
      appointmentEnd
      attendee {
        name
        number
        email
      }
      notifyee {
        name
        number
        email
      }
      meetingType
      status
      regarding
      pushNotificationsSent
      cOgBActorId
      owner
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        cogBActorId
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          logoHeight
          logoWidth
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          canAttachLinks
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
        cogBAppointments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
