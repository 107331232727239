<mat-card class="mat-elevation-z0">
  <mat-card-content>
    <img class="logo" src="../../../assets/logo_new.png" width="300px" height="82px" />
    <ng-container *ngIf="!loadingUser">
      <form autocomplete="disabled" [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" class="full-width-input">
          <mat-label>E-mail</mat-label>
          <input matInput formControlName="username" required>
          <mat-icon matSuffix>mail_outline</mat-icon>
          <mat-error>
            Angiv venligst en gyldig e-mail adresse
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width-input">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" required>
          <mat-icon matSuffix>lock_outline</mat-icon>
          <mat-error>
            Angiv venligst et gyldigt kodeord
          </mat-error>
        </mat-form-field>
        <mat-error *ngIf="loginInvalid" style="margin-bottom: 20px">
          Forkert brugernavn eller kodeord
        </mat-error>
        <button mat-raised-button class="mat-elevation-z0 fluid" color="primary">Log ind</button>
      </form>
      <!--<a routerLink="/forgot" routerLinkActive="forgot"> Glemt kodeord? </a>-->
    </ng-container>
    <div class="loader-container" *ngIf="loadingUser">
      <mat-spinner [strokeWidth]="8" ></mat-spinner>
    </div>
  </mat-card-content>
</mat-card>
<div class="pitch"><p>Vi gør ejendomshandel nemmere</p></div>
