import {Component, OnInit} from '@angular/core';
import {Shop} from "../../services/amplify/models";
import {ShopService} from "../../services/shop/shop.service";
import {filter} from "rxjs/operators";
import {tapOnce} from "../../util/tab-once/tab-once.util";

@Component({
  selector: 'shop-selector',
  templateUrl: 'shop-selector.component.html',
  styleUrls: ['shop-selector.component.scss']
})
export class ShopSelectorComponent implements OnInit {

  shops: Shop[];
  selectedShop: Shop;

  constructor(private shopService: ShopService) {

  }

  ngOnInit() {
    this.shopService.shops.pipe(
      filter(shops => shops.length > 0),
      tapOnce((shops) => {
        this.shops = shops;
        this.selectedShop = shops.filter(shop => shop.id == this.shopService.currentShop$.getValue().shopID)[0];
      })
    )
  }

  selectShop(event) {
    this.selectedShop = event.value;
    this.shopService.selectShop(this.selectedShop);
  }

}
