import {BehaviorSubject} from 'rxjs';
import {VideosAndViewsStatistics} from '../amplify/models';
import {API, graphqlOperation} from 'aws-amplify';
import {Injectable} from '@angular/core';
import * as queries from '../amplify/graphql/queries';
import * as subscriptions from '../amplify/graphql/subscriptions';
import {filter, map, tap} from "rxjs/operators";
import {ShopService} from "../shop/shop.service";

@Injectable()

export class StatisticsService {

  statistics$: BehaviorSubject<VideosAndViewsStatistics[]> = new BehaviorSubject<VideosAndViewsStatistics[]>(null);

  constructor(private shopService: ShopService) {
    this.shopService.currentShop$.pipe(
      filter(shop => {
        return !!shop
      }),
      map(shop => {
        return shop;
      })
    ).subscribe((shop) => {
      this.setVideosAndViewsStatistics(shop.shopID);
      API.graphql(graphqlOperation(subscriptions.onUpdateVideosAndViewsStatistics))
        // @ts-ignore
        .subscribe(update => {
          const list = this.statistics$.getValue();
          const updateObject = update['value'].data.onUpdateVideosAndViewsStatistics;
          const itemToUpdateIndex = list.findIndex(entry => entry.id === updateObject.id);
          list[itemToUpdateIndex] = updateObject;
          this.statistics$.next(list);
        });
      API.graphql(graphqlOperation(subscriptions.onCreateVideosAndViewsStatistics))
        // @ts-ignore
        .subscribe(created => {
          const list = this.statistics$.getValue();
          const createdObject = created['value'].data.onCreateVideosAndViewsStatistics;
          list.push(createdObject);
          this.statistics$.next(list);
        });
    })
  }

  async setVideosAndViewsStatistics(shopID) {
    const response = await API.graphql(graphqlOperation(queries.listVideosAndViewsStatisticss, {
      filter: {
        shopID: {
          eq: shopID
        }
      },
      limit: 50000
    }));
    this.statistics$.next(response['data'].listVideosAndViewsStatisticss.items.filter(s => s._deleted !== true));
  }

}
