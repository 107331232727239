<div fxLayout="row" fxLayoutGap="5%">
  <div fxFlex="30%">
    <mat-form-field appearance="outline" class="fluid">
      <mat-label>Medarbejder</mat-label>
      <mat-select>
        <mat-option *ngFor="let employee of employees" [value]="employee.id">
          {{ employee.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
