import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './home/login/login.component';
import { ForgotComponent } from './home/forgot/forgot.component';
import { LoginGuard } from './shared/guards/login/login.guard';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { AdminGuard } from './shared/guards/admin/admin.guard';
import { ArticlesComponent } from './home/articles/articles.component';
import { PublicArticleGuard } from './shared/guards/public-article/public-article.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'article/:id',
    component: ArticlesComponent,
    canActivate: [PublicArticleGuard]
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: '**',
    redirectTo: 'login'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //enableTracing: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
