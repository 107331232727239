import { RoutingStateService } from './services/routing-state/routing-state.service';
import { SafePipe } from './pipes/safe/safe.pipe';
import { VideoArticleComponent } from './components/video-article/video-article.component';
import { PvpArticleComponent } from './components/pvp-article/pvp-article.component';
import {ModuleWithProviders, NgModule} from '@angular/core';
import { GraphComponent } from './components/graph/graph.component';
import { HeaderComponent } from './components/header/header.component';
import { SearchComponent } from './components/search/search.component';
import { TableComponent } from './components/table/table.component';
import { ShopSelectorComponent } from "./components/shop-selector/shop-selector.component";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatOptionModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthService } from "./services/auth/auth.service";
import { StatisticsService } from "./services/statistics/statistics.service";
import { VideosService } from "./services/videos/videos.service";
import { UsersService } from "./services/users/users.service";
import { MatSortModule } from '@angular/material/sort';
import { VideosharesService } from "./services/videoshares/videoshares.service";
import { ShopService } from "./services/shop/shop.service";

@NgModule({
  declarations: [
    GraphComponent,
    HeaderComponent,
    SearchComponent,
    TableComponent,
    ShopSelectorComponent,
    PvpArticleComponent,
    VideoArticleComponent,

    SafePipe
  ],
  imports: [
      // Material
      MatToolbarModule,
      MatInputModule,
      MatCardModule,
      MatMenuModule,
      MatIconModule,
      MatButtonModule,
      MatTableModule,
      MatOptionModule,
      MatToolbarModule,
      MatSidenavModule,
      MatListModule,
      MatTabsModule,
      MatSelectModule,
      MatSortModule,
      NgApexchartsModule,
      RouterModule,
      FlexLayoutModule,
      CommonModule
  ],
  exports: [
    GraphComponent,
    HeaderComponent,
    SearchComponent,
    TableComponent,
    ShopSelectorComponent,
    PvpArticleComponent,
    VideoArticleComponent,

    // Material
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatOptionModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatTabsModule,
    MatSelectModule,
    MatSortModule
  ],
  providers: [

  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthService,
        ShopService,
        StatisticsService,
        VideosService,
        VideosharesService,
        UsersService,
        RoutingStateService,

        SafePipe
      ]
    };
  }
}
